// PromotionBanner.js
import React, { useState } from 'react';
import './PromotionBanner.css'; // You can create a CSS file for styling

const PromotionBanner = () => {
    const [showBanner, setShowBanner] = useState(true);

    const closeBanner = () => {
        setShowBanner(false);
    };

    return (
        <>
            {showBanner && (
                <div className="promotion-banner glassmorphism">
                    <button className="close-button" onClick={closeBanner}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                        >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                    <div className="banner-content">
                        <img
                            src={`${process.env.PUBLIC_URL}/Promotions/Black-Friday.webp`} // Adjust image path
                            alt="Promotion"
                            className="promotion-image"
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default PromotionBanner;
